

























import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { getTxnStatusLabel } from "@/utils/common";
import { Transaction } from "@/proto/transactions/all_pb";
import { CustomTable } from "@/components";
import FundTransferRow from "./components/FundTransferRow.vue";
import TransactionDetailsModal from "@/components/account-details/components/TransactionDetailsModal.vue";

const transactionsModule = namespace("transactions");

@Component({
  components: {
    CustomTable,
    FundTransferRow,
    TransactionDetailsModal,
  },
})
export default class FundTransferList extends Vue {
  @transactionsModule.Action("getSingleTransactions")
  getSingleTransactions!: () => Array<any>;
  @transactionsModule.State("transactions") transactions!: Array<any>;

  columns = {};
  transactionList: Array<any> = [];
  selectedTransaction = {};

  get COLUMNS() {
    return [
      {
        field: "transaction",
        label: "Transaction",
        sortable: false,
      },
      {
        field: "date",
        label: "Date",
        sortable: true,
        filter: true,
        dataType: "date",
      },
      {
        field: "amount",
        label: "Amount",
        sortable: true,
      },
      {
        field: "receiverName",
        label: "Account Name",
        sortable: true,
      },
      {
        field: "status",
        label: "Status",
        sortable: false,
        dataType: "checkbox",
        filter: true,
        options: [
          {
            label: "PENDING",
            value: "pending",
          },
          {
            label: "SUCCESS",
            value: "success",
          },
          {
            label: "REJECTED",
            value: "rejected",
          },
        ],
      },
      {
        field: "actions",
        label: "Actions",
        sortable: false,
      },
    ];
  }

  mounted() {
    this.getSingleTransactions();
  }

  @Watch("transactions", { immediate: true })
  watchTransactions(transactions: Array<any>) {
    if (transactions.length) {
      this._generateTransactionList();
    }
  }

  _generateTransactionList() {
    this.transactionList = this.transactions;

    if (this.transactions.length) {
      this.transactionList = this.transactions.map(
        (transaction: Transaction.AsObject) => {
          return {
            date: transaction.date.seconds,
            accountName: transaction.sourceaccount?.ownername,
            receiverName: transaction.destinationaccount?.ownername,
            remarks: transaction.remarks,
            amount: transaction?.amount?.num,
            status: getTxnStatusLabel(transaction.status),
            id: transaction.transactionid,
          };
        }
      );
    }

    return [];
  }

  _showDetails(row: Record<string, any>) {
    this.selectedTransaction = this.transactions.find(
      (txn: Transaction.AsObject) => {
        return txn.transactionid === row.id;
      }
    );
    this.$modal.show("transaction-details-modal");
  }
}
