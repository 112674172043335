





































































































import { Vue, Component } from "vue-property-decorator";

import { SvgIcon, TransferFundModal } from "@/components";
import { PaymentMode } from "@/types";

@Component({
  components: {
    SvgIcon,
    TransferFundModal,
  },
})
export default class FundTransferPaymentMode extends Vue {
  EFT: string = PaymentMode.EFT;
  Pesonet: string = PaymentMode.Pesonet;
  Instapay: string = PaymentMode.Instapay;
  paymentMode: PaymentMode | null = null;

  _selectPaymentMode(paymentMode: PaymentMode) {
    this.paymentMode = paymentMode;
    this.$modal.show("transfer-modal");
  }
}
