

















import { Vue, Component } from "vue-property-decorator";

import { Tabs, TabItem } from "@/components";
import FundTransferPaymentMode from "./FundTransferPaymentMode.vue";
import FundTransferList from "./FundTransferList.vue";

@Component({
  components: {
    Tabs,
    TabItem,
    FundTransferPaymentMode,
    FundTransferList,
  },
})
export default class FundTransfer extends Vue {}
